import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { PAGE_VIEWED } from '../../helpers/events'

export default function useTrackPageView({ logEvent }) {
  const router = useRouter()

  const log = () => {
    logEvent(PAGE_VIEWED, {
      path: router.pathname,
      hash: window.location.hash,
      query: router.query,
    })
  }

  useEffect(() => {
    window.onhashchange = log

    return () => {
      window.onhashchange = undefined
    }
  }, [])

  useEffect(() => {
    log()
  }, [router.pathname, router.query, router])
}
